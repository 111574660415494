<template>
  <div @click="$emit('clickAction', location)">
  <v-tooltip bottom v-if="location.container != null">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        :class="['position', handleClasses()]"
      >
      <span>{{ location.name }}</span>
      </div>
    </template>
    <span>{{ location.container.matricula}} </span>
  </v-tooltip>

  <div v-else :class="['position', handleClasses()]">
    <span>{{ location.name }}</span>
  </div>
  </div>
      
</template>

<script>
export default {
  name: 'Position',
  props: {
    location: {
      type: Object,
      required: true,      
    },
    clickAction: {
      type: Function,
      required: false
    }
  },
  watch: {
    location: {
      handler: function (val) {
        console.log(val);
      },
      deep: true
    }
  },
  methods: {
    handleClasses() {      
      if(this.location.container != null) {                
        switch(this.location.container.state) {
          case "Lleno": 
            return "position-full";
          case "Vacio": 
            return "position-no-full"
        }
        return "position-no-state";
      } else {
        return "no-container"
      }
    }
  }
};
</script>
