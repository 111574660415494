<template>
    <div class="zone">
      <h2 class="zone-title">{{ zone.name }}</h2>
      <div class="grid" :style="{ gridTemplateColumns: `repeat(${zone.cols}, 1fr)` }">
        
        <div v-for="(row, rowIndex) in zone.positions" :key="'yy_'+rowIndex">
              
            <div v-for="(location, colIndex) in row" :key="'xx_'+rowIndex+'_'+colIndex">
              <Position :location="location" @clickAction="$emit('clickAction', location)" />
                
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Position from './Position.vue';
  
  export default {
    name: 'Zone',
    components: { Position },
    props: {
      zone: {
        type: Object,
        required: true
      },
      clickAction: {
        type: Function,
        required: false
      }
    },
    methods: {
    }
  };
  </script>
  

  