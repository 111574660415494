import { wsService } from "./wsService";

const section = "roles";

function listModules() {
  return wsService.make_get_request(section + "/modules/list")
}

function filter(param) {
  return wsService.make_post_request(section + "/list", param);
}

function getEnabled() {
  return filter(section + "/list", { enabled: 1 });
}

function create(param = {}) {  
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

export const rolService = {
  section,
  listModules,
  filter,
  remove,
  create,
  update,
  getEnabled,
};
