<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', $event.target.value)" max-width="500px">
      <v-card>
        <v-form v-on:submit.prevent="updateState">
          <v-card-title>
            <span class="headline">{{ GetTitle() }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="item.state"
                  data-vv-as="estado"
                  name="state"
                  :items="states"
                  label="Estado*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("state") }}</span>
              </div>              
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  props: {
    parent: Object,    
    modal_title: String,
    dialog: Boolean,
    item: Object,    
    formBtn: String,
    states_list: Array,
    selected: Array
  },
  data() {
    return {
      show: false,      
      zones: [],
      states: this.states_list,
      submit_loading:false
    };
  },
  watch: {
    item() {      
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {        
    
  },
  methods: {    
    isViewModal() {
      return this.formBtn == "";
    },    
    GetTitle(){
      if(this.item != null && this.item.id != null)
        return this.item.matricula;
      if(this.selected.length > 0)
        return this.selected.length + " contenedores seleccionados";
      return "Error";
    },
    updateState(e) {
      if(this.loading)
        return;
      this.submit_loading = true;   
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
        }

        //TODO: Ver si es item o selected - if(item != null && item.id != null)
        console.log(this.item != null && this.item.id != null)
        console.log(this.item)
        if(this.item != null && this.item.id != null){        
          generalService
              .custom_post(this.item,Constant.SECTION_CONTAINERS+"/update/state")
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El "+this.modal_title+" se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> {
                this.submit_loading = false;
              });
        }else{
          console.log(this.selected)
          const params = {
            ids: this.selected.map((item) => item.id),
            state: this.item.state
          };
          generalService
              .custom_post(params,Constant.SECTION_CONTAINERS+"/massive_update/state")
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("Los "+this.selected.length+" contenedores se modificaron correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if(Object.prototype.hasOwnProperty.call(response, 'message')){
                    this.$toast.error(response.message, "Error");
                  }else{
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(()=> {
                this.submit_loading = false;
              });
        }
      });
    },
  },
};
</script>
