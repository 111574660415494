<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="closeDialog" v-on:input="$emit('updateDialog', $event.target.value)" max-width="750px">
      <template v-slot:activator="{ on }" v-if="show_button">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ (movementItem.container != null)?movementItem.container.matricula:"Mover contenedor" }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="movementItem.container"
                  data-vv-as="container"
                  name="container"
                  item-text="matricula"
                  item-value="id"
                  :items="containers"
                  label="Contenedor*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("container") }}</span>
              </div>

              <div class="col-md-12">
                <v-combobox
                  v-model="zone_selected"
                  data-vv-as="zona"
                  name="zone_selected"
                  item-text="name"
                  item-value="id"
                  :items="zones"
                  label="Zona*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                  @change="onChangeZone"
                ></v-combobox>
                <span class="red--text">{{ errors.first("zone_selected") }}</span>
              </div>

              <div class="col-md-12">
                <v-combobox
                  v-model="movementItem.location"
                  data-vv-as="ubicacion"
                  name="location"
                  item-text="name"
                  item-value="id"
                  :items="locations"
                  label="Ubicacion*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal() || zone_selected == null"                  
                ></v-combobox>
                <span class="red--text">{{ errors.first("location") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDialog">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  props: {
    parent: Object,    
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    show_button: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,  
      zone_selected: null,    
      zones: [],
      containers: [],
      locations: [],
      movementItem: {
        container: null,        
        location: null,
      },
      defaultItem: {
        container: null,        
        location: null,
      },
    };
  },
  watch: {
    item() {
      if(this.item != null && this.item.id > 0){
        console.log(this.item);
        this.movementItem.container = this.item;
      }
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {        
    this.loadComboData();
  },
  methods: {
    closeDialog() {
      this.movementItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.$emit("updateDialog", false);
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_ZONES).then((response) => {
        this.zones = response.result.list;        
      });
      generalService.filter({}, Constant.SECTION_CONTAINERS).then((response) => {        
        this.containers = response.result.list;
        if(this.item != null && this.item.id > 0){
          this.movementItem = this.containers.find((element) => element.id == this.item.id);
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },    
    createItem(e) {
      if(this.loading)
        return;
      this.setLoading(true);   
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
        }
                
        generalService.setSection(Constant.SECTION_CONTAINERS+"/move");
        generalService
            .create(this.movementItem)
            .then((response) => {
              if (response.status == true) {
                this.$toast.success("El "+this.modal_title+" se modifico correctamente.", "OK");
                this.closeDialog();
                this.$emit("loadList");
              } else {
                if(Object.prototype.hasOwnProperty.call(response, 'message')){
                  this.$toast.error(response.message, "Error");
                }else{
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(()=> this.setLoading(false));
      });
    },
    onChangeZone(){
      if(this.zone_selected != null && this.zone_selected.id > 0){
        generalService
            .custom_post({ zone_id: this.zone_selected.id}, Constant.SECTION_ZONES+"/list/available-locations")
            .then((response) => {
              if (response.status == true) {
                console.log(response.result)
                this.locations = response.result;
              } else {
                if(Object.prototype.hasOwnProperty.call(response, 'message')){
                  this.$toast.error(response.message, "Error");
                }else{
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(()=> this.setLoading(false));
      }
    }
  },
};
</script>
