<template>
  <div>
    <Title :pages="[{ icon: 'box', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalMoveContainer
        v-if="create_access"
        :button_name="$t('Mover') + ' ' + modal_title"
        :modal_title="modal_title"
        formBtn="Mover"
        :dialog="dialog_move"
        :parent="parent"
        :service="service"
        @updateDialog="moveContainer"
        @loadList="filter"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalEstado
        v-if="create_access"        
        :modal_title="modal_title"
        formBtn="Actualizar"
        :dialog="dialog_state"
        :selected="selected"
        :parent="parent"
        :service="service"
        @updateDialog="closeModalstate"
        @loadList="filter"
        :item="mainItem"
        :states_list="states"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0 ">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde*" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0 ">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta*" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="tipo_fecha_selected" autocomplete="off" data-vv-as="tipos_fechas" name="tipos_fechas" :items="tipos_fechas" label="Fecha*" class="pt-0 mt-0" allow></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    multiple
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client"
                    name="client"
                    item-text="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    class="pt-0 mt-0"
                    allow
                  ></v-combobox>
                </div>
                
                
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="matricula_selected" autocomplete="off" data-vv-as="matricula" name="matricula" label="Matricula" class="pt-0 mt-0"></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="booking_selected" autocomplete="off" data-vv-as="bl" name="booking_selected" label="BL" class="pt-0 mt-0"></v-text-field>
                </div>
                
                <div class="col-md-3 pt-0">
                  <v-combobox                    
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="estado"
                    name="state"
                    item-text="state"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    allow
                  ></v-combobox>
                </div>
                                              

                <div class="col-md-3 pt-0" style="display: flex;justify-content: end;">
                  <!--<v-btn color="error" class="mr-2" @click="export_filter">Exportar</v-btn>-->
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <div v-if="selected.length != 0" class="col-md-12" style="background: #f4f1f1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" class="cursor mb-1 mt-1" @click="massiveUpdateState">edit</v-icon>
            </template>
            <span>Cambiar estado</span>
          </v-tooltip>
        </div>

        <v-data-table 
          :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..." 
          show-select
          :single-select="false"
          v-model="selected"
          :footer-props="{
            'items-per-page-options': [50, 100, 200]
          }"
          :items-per-page="50"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr>
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" />
              </td>
              <td>{{ item.matricula }}</td>
              <td>{{ item.booking }}</td>
              <td>{{ (item.location != null)?item.location.zone.name+" - "+item.location.name:"" }}</td>
              <td>{{ item.client.name }}</td>
              <td>{{ item.state }}</td>
              <td>{{ item.date_entry }}</td>
              <td>{{ item.date_departure }}</td>
              <td class="justify-content-center layout px-0">                
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-1" @click="moveContainer(true,item)">
                      sync
                    </v-icon>
                  </template>
                  <span>Mover Contenedor</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-1" @click="updateState(true,item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Cambiar Estado</span>
                </v-tooltip> 

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="purple" class="mr-1" @click="openHistory(item)">
                      timer
                    </v-icon>
                  </template>
                  <span>Ver Historial</span>
                </v-tooltip>          
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <HistoryModal :open="dialog_history" :item="mainItem" @close="closeHistory()"/>
      
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import FechasType from "@/constants/fechas_type";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import ModalMoveContainer from "./ModalMoveContainer.vue";
import ModalEstado from "./ModalEstado.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import HistoryModal from "./HistoryModal.vue";

export default {
  components: { Title, ModalMoveContainer, HistoryModal, ModalEstado },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      selected: [],
      parent: this,
      service: generalService,
      page_title: this.$t("Contenedores"),
      modal_title: this.$t("Contenedor"),
      mainItem: {
        id: "",
        matricula: "",
        client: null,
        zone: null,
        date: null,
        date_entry: null,
        date_departure: null,
        state: null,
      },
      defaultItem: null,
      headers: [        
        {
          text: "Matricula",
          align: "left",
          sortable: true,
          value: "matricula",
        },
        {
          text: "BL",
          align: "left",
          sortable: true,
          value: "booking",
        },
        {
          text: "Ubicacion",
          align: "left",
          sortable: true,
          value: "location.name",
        },
        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "client.name",
        },
        {
          text: "Estado",
          align: "left",
          sortable: true,
          value: "state",
        },
        {
          text: "Fecha Ingreso",
          align: "left",
          sortable: true,
          value: "date_entry",
        },
        {
          text: "Fecha Egreso",
          align: "left",
          sortable: true,
          value: "date_departure",
        },        
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_move: false,
      dialog_state: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      tipos_fechas: FechasType.GetFechasTipoContainer(),
      tipo_fecha_selected: FechasType.GetFechasTipoContainer()[0],
      matricula_selected: "",
      client_selected: [],
      state_selected: [],
      booking_selected: "",
      clients: [], 
      states: [
         "Lleno",
         "Vacio",
      ],
      dialog_history: false,          
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = Object.assign({}, this.mainItem);
    this.service.setSection(Constant.SECTION_CONTAINERS);
    this.filter();
  },
  methods: {
    getFilterParams() {
      const param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        tipo_fecha: this.tipo_fecha_selected,        
        client: this.client_selected,
        matricula: this.matricula_selected,
        booking: this.booking_selected,
        state: this.state_selected,
      };
      return param;
    },
    filter() {      
      this.service.setSection(Constant.SECTION_CONTAINERS);      
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result.list;
      });
    },
    moveContainer(dialog_value, item = null) {
      if(item != null)
        this.mainItem = item;
      else
        this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog_move = dialog_value;
    },
    openHistory(item) {
      //this.$refs.historialModal.open(container_id, matricula);
      this.dialog_history = true;
      this.mainItem = JSON.parse(JSON.stringify(item));
    },
    closeHistory(){
      this.dialog_history = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    updateState(dialog_value, item = null) {
      if(item != null)
        this.mainItem = item;
      this.dialog_state = dialog_value;
    },
    closeModalstate(){
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog_state = false;
    },
    massiveUpdateState(){
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.mainItem.id = null;
      this.updateState(true,this.mainItem);
    }
  },
};
</script>
