<template>
  <v-card @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" height="100vh" width="256"
    class="mx-auto">
    <v-navigation-drawer v-model="$store.state.sidebar.drawer" :width="240" dark app
      :expand-on-hover="!$store.state.sidebar.open" class="rounded-0">
      <div>
        <v-list style="height:100px">
          <v-list-item>
            <v-list-item-content class="pt-1">
              <v-img v-show="expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" contain
                max-width="85" class="pt-0" style="margin-left: 25%;" />

              <v-img v-show="!expandMenu" transition="fade-transition" :src="require('@/assets/images/logo-mini.png')"
                contain height="60" class="pt-8" />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="mt-0"></v-divider>
      </div>

      <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
        <!-- <span v-if="$store.state.sidebar.open"></span> -->
        <p class="title-section mb-0" v-if="item.visible && expandMenu"> {{ item.section }}</p>
        <p class="title-section mb-0" v-else-if="item.visible && !expandMenu">{{ item.section_collapse }}</p>
        <div v-for="child in item.children" :key="child.title">

          <v-list v-if="child.visible" dense nav>
            <v-list-group v-if="child.visible && 'items' in child && child.items.length > 0" v-model="child.active"
              :key="child.title" no-action>
              <v-list-item slot="activator">
                <v-list-item-icon>
                  <font-awesome-icon :icon="child.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <router-link v-for="subItem in child.items" :key="subItem.title" :to="'/' + subItem.path">
                <v-list-item v-if="subItem.visible" ripple @click="close" class="subitem">
                  <!-- <v-list-item-icon>
                    <font-awesome-icon :icon="subItem.icon" />
                  </v-list-item-icon> -->
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list-group>

            <router-link v-else-if="child.visible" :to="'/' + child.path">
              <v-list-item :key="child.title" link>
                <v-list-item-icon>
                  <font-awesome-icon :icon="child.icon" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Constant from "@/constants/sections";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  mixins: [RolMixin],
  RolMixin,
  data() {
    return {
      isExternal: null,
      items: [        
        {
          visible: false,
          children: [
            {
              title: this.$t("Dashboard"),
              icon: "tachometer-alt",
              active: false,
              path: Constant.SECTION_DASHBOARD,
              visible: false,
            },
            {
              title: this.$t("Contenedores"),
              icon: "box",
              active: false,
              path: Constant.SECTION_CONTAINERS,
              visible: false,
            },
            {
              title: this.$t("Reportes"),
              icon: "file",
              active: false,
              path: Constant.SECTION_REPORTS,
              visible: false,
            },
            {
              title: this.$t("Clientes"),
              icon: "users",
              active: false,
              path: Constant.SECTION_CLIENTS,
              visible: false,
            },
            {
              title: this.$t("Zonas"),
              icon: "map",
              active: false,
              path: Constant.SECTION_ZONES,
              visible: false,
            },
          ]
        },                
        { 
          visible: false,
          children: [
            {
              title: this.$t("Administrar"),
              icon: "cog",
              active: false,
              visible: false,
              items: [
                { title: this.$t("Operadores"), path: Constant.SECTION_OPERATORS, visible: false },
                { title: this.$t("Roles"), path: Constant.SECTION_ROLES, visible: false },
                { title: this.$t("Usuarios"), path: Constant.SECTION_USERS, visible: false },
              ],
            }
          ],

        },
      ],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();    
  },
  watch: {
    "$store.state.sidebar.open": function () {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = false;
      }
    },
  },
  methods: {
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = value;
      }
    },
    close() { },
    loadSideBarExternal() {
      this.items = this.getItemsAvailable();      
    },
    getItemsAvailable() {
      let itemsAvailable = this.items;
      itemsAvailable.forEach((item) => {
        if ("children" in item) {
          item.visible = true;
          let any_visible = false;
          item.children.forEach((child) => {
            if ("items" in child) {
              child.visible = true
              child.items.forEach((sub_item) => {
                sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
                if (sub_item.visible){
                  any_visible = true;
                }
              });
            } else {

              child.visible = child.path in this.roles && this.roles[child.path].sidebar;
              if (child.visible) any_visible = true;
            }
          });

          item.visible = any_visible;
        } else {
          item.visible = item.path in this.roles && this.roles[item.path].sidebar;
        }
      });

      return itemsAvailable;
    },
  },
};
</script>
