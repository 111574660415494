import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BNav } from "bootstrap-vue";

import {
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faTruckMoving,
  faTag,
  faShieldAlt,
  faClipboardList,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faDownload,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faFileInvoice,
  faDollarSign,
  faPlaneDeparture,
  faBuilding,
  faBox,
  faMap,  
  faFile,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFile,
  faTachometerAlt,
  faBuilding,
  faPlaneDeparture,
  faDollarSign,
  faFileInvoice,
  faTruckMoving,
  faDownload,
  faTag,
  faShieldAlt,
  faClipboardList,
  faUserSecret,
  faDesktop,
  faRoad,
  faTruck,
  faUsers,
  faCog,
  faMapMarker,
  faChartBar,
  faRoute,
  faMapPin,
  faMapMarkedAlt,
  faTruckLoading,
  faDolly,
  faFilter,
  faChevronDown,
  faChevronUp,
  faBan,
  faUserCog,
  faReceipt,
  faCrosshairs,
  faFileExcel,
  faBox,
  faMap,
);

export default FontAwesomeIcon;
