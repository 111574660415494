<template>
    <v-card>
      <div class="container-zonegroup">
        <v-skeleton-loader
          v-for="n in 3"
          :key="n"
          type="image"
          class="card-zonegroup"
          style="display: inline !important"
        ></v-skeleton-loader>
      </div>
      <div class="zones">
        <div class="row justify-content-center">            
            <v-skeleton-loader
              v-for="n in 8"
              :key="n"
              type="image"
              class="zone"
              style="display: inline !important; padding: 0 !important;"

            ></v-skeleton-loader>
        </div>
      </div>
    </v-card>
  </template>
  
  <script>
  export default {
    name: "SkeletonZoneGroup",
  };
  </script>
  