<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', $event.target.value)" max-width="750px">

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>                                                
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" :loading="submit_loading" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import ModalMixin from "@/mixins/ModalMixin.vue";
export default {
  mixins: [ModalMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {        
    
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
  }    
};
</script>
