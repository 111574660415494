<template>
  <div>
    <Title :pages="[{ icon: 'chart-bar', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid v-if="list_access">
      <v-form>
        <div class="row filter justify-content-md-center mt-10">
          <div class="col-md-5">                   
            <div class="row mt-10" style="justify-content:center ; display: flex ; text-align: center">
              
              <div class="col-md-12" >
                <v-combobox 
                  label="Tipo de reporte*" name="tipo_reporte" :items="tipos_reportes" 
                  item-text="name"
                  item-value="id" v-model="tipo_reporte" v-validate="'required'"></v-combobox>
                <span class="red--text">{{ errors.first("tipodeinforme") }}</span>
              </div>          

              <div class="col-md-12" v-if="tipo_reporte != ''">
                <v-text-field
                  label="Desde*"
                  data-vv-as="desde"
                  name="desde_selected"
                  v-model="desde_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("desde_selected") }}</span>
              </div>
            
            
              <div class="col-md-12" v-if="tipo_reporte != ''">
                <v-text-field
                  label="Hasta*"
                  data-vv-as="hasta"
                  name="hasta_selected"
                  v-model="hasta_selected"
                  type="date"
                  v-validate="'required'"
                  autocomplete="off" 
                ></v-text-field>
                <span class="red--text">{{ errors.first("hasta_selected") }}</span>                
              </div>               

            </div>

            <div class="row mb-2" style="justify-content:center ; display: flex ; text-align: center">              
              <v-btn :loading="loading_reporte" @click="generateReport()" color="primary">
                Descargar
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/sections";
import Title from "@/components/Title.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title },
  mixins: [GenericMixin,RolMixin],
  data() {
    return {   
      page_title: "Reportes",            
      tipos_reportes: [
        { id: "ingresos-egresos", name: "Ingresos/Egresos"},
      ],
      loading_reporte: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      service: generalService,

      tipo_reporte: "",      
    };
  },
  mounted() {
    this.loadComboData();
  },
  methods: {    
    loadComboData() {

    },
    generateReport(){
      if(this.tipo_reporte.id == undefined){
        this.$toast.error("Debe seleccionar un tipo de reporte", "Error");
        return;
      }

      this.$validator.validate().then((valid) => {
        if (!valid ) {
          this.$toast.error("Debe completar los campos requeridos", "Error");
          return;
        }

        this.loading_reporte = true;
        this.service.setSection(Constant.SECTION_REPORTS);        
        this.service.custom_export(
          this.getFilterParams(),
          Constant.SECTION_REPORTS+"/"+this.tipo_reporte.id)
        .then((response) => {
          
          try {
            //Transformo a string la respuesta para poder enviar mensajes de error.
            let result = JSON.parse(this.arrayBufferToString(response));            console.log(result);
            console.log(result);
            if (!result.status) {
              this.$toast.error(result.msg, "Error");
            }
          } catch (e) {
            console.log(e);
            let blob = new Blob([response], {
              type: "application/vnd.ms-excel",
            });
            let link = document.createElement("a");
            //link.setAttribute('download', "export")
            link.download = this.getExportName()+".xlsx";
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            //link.download = code+'.pdf'
            link.click();
          }
        })
        .catch((error) => {
          this.$toast.error("Error al descargar el reporte", "Error");
        })
        .finally(() => {
          this.loading_reporte = false;
        });
      });
    },
    getExportName(){
      //Get now Y-m-d string
      let date = new Date();           

      switch(this.tipo_reporte.id){
        case "ingresos-egresos":
          return "ingresos-egresos_"+this.desde_selected+"_"+this.hasta_selected;        
        default:
          return "Reporte_"+this.desde_selected+"_"+this.hasta_selected;

      }
    },
    getFilterParams() {
      let param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,         
      };
      
      return param;
    },
  },
};
</script>
