import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Login from "@/views/Login/Login.vue";
import Usuarios from "@/views/Usuarios/Usuarios.vue";
import Operators from "../views/Operators/Operators.vue";
import Roles from "@/views/Roles/Roles.vue";

import Zones from "@/views/Zones/Zones.vue";
import Clients from "@/views/Clients/Clients.vue";
import Containers from "@/views/Containers/Containers.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Reports from "@/views/Reports/Reports.vue";

import { authService } from "@/libs/ws/authService";
import Constant from "@/constants/sections";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/users",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_ZONES,
    name: "Zonas",
    component: Zones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS,
    name: "Clientes",
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CONTAINERS,
    name: "Contenedores",
    component: Containers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_OPERATORS,
    name: "Operadores",
    component: Operators,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORTS,
    name: "Reportes",
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (authService.isAuthenticated() && to.path == "/login") {
      const redirect = localStorage.getItem("redirect");
      next({
        path: redirect,
      });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
