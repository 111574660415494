<template>
  <div>
    <Title :pages="[{ icon: 'tachometer-alt', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>   

      <ModalMoveContainer
        v-if="create_access"
        :show_button="false"        
        :button_name="$t('Mover')"
        modal_title="Mover Contenedor"
        formBtn="Mover"
        :dialog="dialog_move"
        :parent="parent"
        :service="service"
        @updateDialog="moveContainer"
        @loadList="getMap"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />  

      <v-card v-if="list_access">
        <v-card-title>          
          <v-spacer></v-spacer>
          
        </v-card-title>
        <div v-if="loading">
          <SkeletonDashboard/>
        </div>
        <div v-else>
          <div class="">
            <div class="col-md-12 col-xs-12">
              <div class="box-monitoring-mother">
                  <div class="row">
                      <div class="col box-monitoring divider">
                          <p>Ubicaciones ocupadas</p>
                          <div v-if="!loading_monitoring">
                              {{ ubicaciones_ocupadas }}
                          </div>
                          <div v-if="loading_monitoring" class="skeleton_monitoring">
                              <v-skeleton-loader
                                  style="display: inline-block;margin-top: 5px;"
                                  v-if="loading_monitoring"
                                  type="chip"
                              />
                          </div>
                      </div>
                      <div class="col box-monitoring divider">
                          <p>Ubicaciones libres</p>
                          <div v-if="!loading_monitoring">
                              {{ ubicaciones_libres }}
                          </div>
                          <div v-if="loading_monitoring" class="skeleton_monitoring">
                              <v-skeleton-loader
                                  style="display: inline-block;margin-top: 5px;"
                                  v-if="loading_monitoring"
                                  type="chip"
                              />
                          </div>
                      </div>
                      <div class="col box-monitoring divider">
                          <p><v-badge color="green" class="left mr-5"></v-badge>Contenedores llenos</p>                                                       
                          <div v-if="!loading_monitoring">
                              {{ contenedores_llenos }}                              
                          </div>
                          <div v-if="loading_monitoring" class="skeleton_monitoring">
                              <v-skeleton-loader
                                  style="display: inline-block;margin-top: 5px;"
                                  v-if="loading_monitoring"
                                  type="chip"
                              />
                          </div>
                      </div>
                      <div class="col box-monitoring divider">
                          <p><v-badge color="red" class="left mr-5"></v-badge>Contenedores vacios</p>
                          <div v-if="!loading_monitoring">
                              {{ contenedores_vacios }}
                          </div>
                          <div v-if="loading_monitoring" class="skeleton_monitoring">
                              <v-skeleton-loader
                                  style="display: inline-block;margin-top: 5px;"
                                  v-if="loading_monitoring"
                                  type="chip"
                              />
                          </div>
                      </div>
                      <div class="col box-monitoring divider">
                          <p><v-badge color="grey" class="left mr-5"></v-badge>Contenedores sin estado</p>
                          <div v-if="!loading_monitoring">
                              {{ contenedores_sin_estado }}
                          </div>
                          <div v-if="loading_monitoring" class="skeleton_monitoring">
                              <v-skeleton-loader
                                  style="display: inline-block;margin-top: 5px;"
                                  v-if="loading_monitoring"
                                  type="chip"
                              />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="container-zonegroup"  >
            <div
              v-for="zone_group in map" :key="zone_group.id" 
              class="card-zonegroup"
              :class="{ 'disabled-card': selectedZoneGroup !== zone_group, 'enabled-card': selectedZoneGroup === zone_group  }"
              @click.stop="selectZoneGroup(zone_group)"
            >
              {{ zone_group.zone_group.name }}
            </div>
          </div>
          <div class="zones" v-if="selectedZoneGroup">
            <div class="row justify-content-center">
              <Zone v-for="zone in selectedZoneGroup.zones" :key="zone.id" :zone="zone" @clickAction="clickAction" />
            </div>
          </div>
        </div>

      </v-card>      
    </v-container>
  </div>
</template>
<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Zone from "./Zone.vue";
import SkeletonDashboard from "./SkeletonDashboard.vue";
import ModalMoveContainer from "../Containers/ModalMoveContainer.vue";

export default {
  components: { Title, Zone ,SkeletonDashboard, ModalMoveContainer},
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      parent: this,
      service: generalService,
      page_title: this.$t("Dashboard"),      
      map: [],
      loading: false,
      loading_monitoring: false,
      selectedZoneGroup: null,
      mainItem: {
        id: "",
        matricula: "",
        client: null,
        zone: null,
        date: null,
        date_entry: null,
        date_departure: null,
        state: null,
      },
      defaultItem: null,
      dialog_move: false,

      ubicaciones_ocupadas: null,
      ubicaciones_libres: null,
      contenedores_llenos: null,
      contenedores_vacios: null,
      contenedores_sin_estado: null,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.getMap();
    this.getMonitoring();
  },
  methods: {
    clickAction(item){
      this.moveContainer(true, item.container);
    },
    moveContainer(dialog_value, item = null) {
      if(item != null)
        this.mainItem = item;
      else
        this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.dialog_move = dialog_value;
    },
    getColor(row){
      return row.full ? 'square red' : 'square green';
    },
    selectZoneGroup(zone_group) {
      this.selectedZoneGroup = zone_group;
    },
    getMonitoring(){
      this.loading_monitoring = true;
      generalService
        .custom_post({}, Constant.SECTION_ZONES+"/monitoring")
        .then((response) => {
          if (response.status == true) {            
            this.ubicaciones_ocupadas = response.result.ubicaciones_ocupadas;
            this.ubicaciones_libres = response.result.ubicaciones_libres;
            this.contenedores_llenos = response.result.contenedores_llenos;
            this.contenedores_vacios = response.result.contenedores_vacios;
            this.contenedores_sin_estado = response.result.contenedores_sin_estado;         
          } else {
            if(Object.prototype.hasOwnProperty.call(response, 'message')){
              this.$toast.error(response.message, "Error");
            }else{
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(()=> this.loading_monitoring = false);
    },
    getMap(){
      this.loading = true;
      generalService
        .custom_post({}, Constant.SECTION_ZONES+"/list/map")
        .then((response) => {
          if (response.status == true) {            
            this.map = response.result;            
            if(Object.keys(this.map).length > 0){
              const primerasClaves = Object.keys(this.map)[0];
              this.selectZoneGroup(this.map[primerasClaves]);
            }            
          } else {
            if(Object.prototype.hasOwnProperty.call(response, 'message')){
              this.$toast.error(response.message, "Error");
            }else{
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(()=> this.loading = false);
    }
  },
};
</script>
