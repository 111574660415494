const ULTIMO_INGRESO_TIPO_FECHA = "Fecha de Ingreso";//Ultimo significa que solo se fija en el container, NO en el historial
const ULTIMO_EGRESO_TIPO_FECHA = "Fecha de Egreso";

const INGRESO_TIPO_FECHA = "Fecha de Ingreso";
const EGRESO_TIPO_FECHA = "Fecha de Egreso";


function GetFechasTipoContainer() {
  return [
    ULTIMO_INGRESO_TIPO_FECHA,
    ULTIMO_EGRESO_TIPO_FECHA,
  ];
}

function GetFechasTipoHistorial() {
  return [
    INGRESO_TIPO_FECHA,
    EGRESO_TIPO_FECHA,
  ];
}

export default {
  GetFechasTipoContainer,
  GetFechasTipoHistorial
};
