const SECRET_PHRASE = "wegewh84hTRHRt4j9r+*";

const SECTION_ROLES = "roles";
const SECTION_USERS = "users";
const SECTION_OPERATORS = "operators";

const SECTION_DASHBOARD = "dashboard";

const SECTION_ZONES = "zones";
const SECTION_CLIENTS = "clients";
const SECTION_CONTAINERS = "containers";
const SECTION_REPORTS = "reports";

export default {
  SECRET_PHRASE: SECRET_PHRASE,
  SECTION_USERS: SECTION_USERS,
  SECTION_ROLES: SECTION_ROLES,
  SECTION_OPERATORS: SECTION_OPERATORS,

  SECTION_ZONES: SECTION_ZONES,
  SECTION_CLIENTS: SECTION_CLIENTS,
  SECTION_CONTAINERS: SECTION_CONTAINERS,  
  SECTION_REPORTS: SECTION_REPORTS,

  SECTION_DASHBOARD: SECTION_DASHBOARD,
};
