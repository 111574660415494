import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: {
      open: true,
      drawer: true,
    },
    config: {
      items_per_page: 10,
    },
  },
  mutations: {
    setOpenSidebar: function(state, payload) {
      if (payload.open != undefined) state.sidebar.open = payload.open;

      if (payload.drawer != undefined) state.sidebar.drawer = payload.drawer;
    },
  },
  actions: {},
  modules: {},
});
