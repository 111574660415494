<template>
    <v-dialog v-model="open" max-width="600px" @keydown.esc="closeDialog()">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Historial de Movimientos {{ matricula }}</span
                >
            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headers" :items="history" item-key="id">
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item.date }}</td>
                            <td>{{ props.item.movement_type }}</td>
                            <td>{{ (props.item.location_label != null)?props.item.location_label:"-" }}</td>                            
                            <td>
                                {{
                                    props.item.user != null
                                        ? props.item.user.username
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDialog()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
export default {
    name: "HistoryModal",
    props: {
        open: Boolean,
        close: Function,
        item: Object
    },
    data() {
        return {
            matricula: "",
            history: [],
            mainItem: {
                id: 0,
                fecha: "",
                estado: "",
                user: { id: 0, username: "" },
                descripcion: ""
            },
            defaultItem: {
                id: 0,
                fecha: "",
                estado: "",
                user: { id: 0, username: "" },
                descripcion: ""
            },
            headers: [
                {
                    text: "Fecha",
                    align: "left",
                    sortable: true,
                    value: "fecha"
                },
                { text: "Movimiento", value: "movement_type" },                
                { text: "Ubicacion", value: "location.name" },
                { text: "Usuario", value: "user.username" }
            ]
        };
    },
    mounted() {},
    computed: {},
    watch: {
        open() {
            if(this.open)
                this.get_history()
        },
    },
    methods: {
        resetObject() {
            this.mainItem = Object.assign({}, this.defaultItem);
        },
        closeDialog() {
            this.$emit("close");
            this.resetObject();
        },
        get_history() {            
            if (this.open && this.item.id > 0) {
                generalService.filter({ container: { id: this.item.id} }, Constant.SECTION_CONTAINERS + "/history")
                .then((response) => {
                    this.history = response.result.list;                    
                });              
            }
        },        
    }
};
</script>
